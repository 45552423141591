import { ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { OkDialogComponent } from '../../shared/ok-dialog/ok-dialog.component';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/material/dialog";
// Error Handler
var MyErrorHandler = /** @class */ (function () {
    function MyErrorHandler(http, dialog) {
        this.http = http;
        this.dialog = dialog;
    }
    MyErrorHandler.prototype.handleError = function (error) {
        console.error(error);
        // Error with JSON parse -> clear cache (local storage) and reload
        if (error && error.name == 'SyntaxError' && error.message.includes('Unexpected token') && error.message.includes('JSON')) {
            for (var key in localStorage) {
                if (key != 'accessToken')
                    localStorage.removeItem(key);
            }
            console.log(Object.keys(localStorage).length);
            if (document.location.hash != "#error-reload") {
                document.location.hash = "#error-reload";
                document.location.reload();
            }
        }
        if (error.name == 'HttpErrorResponse' && ((error.error && error.error.text) || error.includes("Fehler aufgetreten"))) {
            var error_string = error.error ? error.error.text.replace(/<\/?[^>]+(>|$)/g, "") : '';
            if (this.lastError != error_string || !this.lastErrorTime || Date.now() - this.lastErrorTime.getTime() > 5000) {
                alert(error_string);
            }
            this.lastError = error_string;
            this.lastErrorTime = new Date();
        }
        // Log Error on Server
        if (error.name != 'HttpErrorResponse' && (!this.lastErrorTime || Date.now() - this.lastErrorTime.getTime() > 5000)) {
            this.logError(error);
        }
        throw (error);
    };
    MyErrorHandler.prototype.logError = function (error, show_alert) {
        var _this = this;
        if (show_alert === void 0) { show_alert = true; }
        var payload = {
            app_version: environment.version,
            error_name: error.name,
            error_text: error.message || error.toString(),
            error_content: error.stack + "\n" + window.navigator.vendor + "\n" + window.navigator.userAgent
        };
        this.http.post('/v1/error-log', payload).subscribe(function () {
            console.log('error last', _this.lastErrorTime, _this.lastErrorTime ? Date.now() - _this.lastErrorTime.getTime() : null);
            if (show_alert && (!_this.lastErrorTime || Date.now() - _this.lastErrorTime.getTime() > 5000)) {
                _this.lastErrorTime = new Date();
                alert('Ein unerwarteter Fehler ist aufgetreten. Der Fehler wurde automatisch an Lupax weitergeleitet. Sollte der Fehler häufiger auftreten wenden Sie sich bitte an den Lupax-Support.');
            }
        }, function () {
            if (show_alert && (!_this.lastErrorTime || Date.now() - _this.lastErrorTime.getTime() > 5000)) {
                _this.lastErrorTime = new Date();
                alert('Ein unerwarteter Fehler ist aufgetreten. Der Fehler konnte NICHT an Lupax weitergeleitet werden. Bitte wenden Sie sich an den Lupax-Support.');
            }
        });
    };
    MyErrorHandler.prototype.showError = function (err) {
        console.error(err);
        if (err.error && err.error.text) {
            this.dialog.open(OkDialogComponent, { data: err.error.text.replace(/<\/?[^>]+(>|$)/g, "") });
        }
        else {
            this.dialog.open(OkDialogComponent, { data: 'Unbekannter Fehler' });
            this.logError(err, false);
        }
    };
    MyErrorHandler.ngInjectableDef = i0.defineInjectable({ factory: function MyErrorHandler_Factory() { return new MyErrorHandler(i0.inject(i1.HttpClient), i0.inject(i2.MatDialog)); }, token: MyErrorHandler, providedIn: "root" });
    return MyErrorHandler;
}());
export { MyErrorHandler };
